const SUBDOMAIN = `{yoursubdomainhere}`;

const SALESFORCE_BASE_URL = `https://${SUBDOMAIN}.lightning.force.com/lightning/r`;
const SALESFORCE_ACCOUNT_ID = `{salesforce_account_id}`;

const SERVICENOW_BASE_URL = `https://${SUBDOMAIN}.service-now.com/now/nav/ui/classic/params/target`;
const SERVICENOW_ACCOUNT_SYSID = `{snow_account_sysid}`;
const SERVICENOW_CONTACT_SYSID = `{snow_contact_sysid}`;
const SERVICENOW_INCIDENT_SYSID = `{snow_incident_sysid}`;

export const TEMPLATE_URLS = [
  {
    id: `CustomTemplate`,
    label: `integration.custom`,
    url: ``,
  },
  {
    id: `SalesforceAccount`,
    label: `integration.salesforceAccount`,
    url: `${SALESFORCE_BASE_URL}/Account/${SALESFORCE_ACCOUNT_ID}/view`,
  },
  {
    id: `SalesforceContact`,
    label: `integration.salesforceContact`,
    url: `${SALESFORCE_BASE_URL}/Contact/${SALESFORCE_ACCOUNT_ID}/view`,
  },
  {
    id: `SalesforceOportunity`,
    label: `integration.salesforceOportunity`,
    url: `${SALESFORCE_BASE_URL}/Oportunity/${SALESFORCE_ACCOUNT_ID}/view`,
  },
  {
    id: `SalesforceLeads`,
    label: `integration.salesforceLeads`,
    url: `${SALESFORCE_BASE_URL}/Leads/${SALESFORCE_ACCOUNT_ID}/view`,
  },
  {
    id: `SalesforceQuotes`,
    label: `integration.salesforceQuotes`,
    url: `${SALESFORCE_BASE_URL}/Quotes/${SALESFORCE_ACCOUNT_ID}/view`,
  },
  {
    id: `ServicenowAccount`,
    label: `integration.servicenowAccount`,
    url: `${SERVICENOW_BASE_URL}/customer_account.do?sys_id=${SERVICENOW_ACCOUNT_SYSID}`,
  },
  {
    id: `ServicenowContact`,
    label: `integration.servicenowContact`,
    url: `${SERVICENOW_BASE_URL}/customer_contact.do?sys_id=${SERVICENOW_CONTACT_SYSID}`,
  },
  {
    id: `ServicenowIncident`,
    label: `integration.servicenowIncident`,
    url: `${SERVICENOW_BASE_URL}/incident.do?sys_id=${SERVICENOW_INCIDENT_SYSID}`,
  },
  {
    id: `ServicenowCase`,
    label: `integration.servicenowCase`,
    url: `${SERVICENOW_BASE_URL}/sn_customerservice_case.do?sys_id=${SERVICENOW_INCIDENT_SYSID}`,
  },
];
